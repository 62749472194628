import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { Head,Link } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

//import {InertiaProgress} from "@inertiajs/progress";



import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import axios from "axios";
//InertiaProgress.init();

//APP  https://web.dev/codelab-make-installable/

/* Only register a service worker if it's supported */
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}else{
    console.log('default');
}
//END //APP  https://web.dev/codelab-make-installable/

createInertiaApp({
  //  title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/Front/${name}.vue`, import.meta.glob('./Pages/Front/**/*.vue')),
    setup({el, App, props, plugin}) {
        return createSSRApp({render: () => h(App, props)})
            .component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .mixin({
                methods: {
                    route, asset(path) {
                        if(import.meta.env.VITE_FRONTEND_URL!==undefined){
                            return import.meta.env.VITE_FRONTEND_URL +'storage/'+ path;
                        }
                        if(website_domain && website_domain!==undefined){
                            return 'https://'+website_domain +'/storage/'+ path;
                        }
                        alert('env.VITE_FRONTEND_URL is missing')
                    },
                    env() {
                        return import.meta.env;
                    },
                    money_format(money) {
                       return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR',minimumFractionDigits:0 }).format(money)+',-';
                    },
                    currency(number) {
                        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number);
                    },
                    setCookie(name,value,days) {
                        var expires = "";
                        if (days) {
                            var date = new Date();
                            date.setTime(date.getTime() + (days*24*60*60*1000));
                            expires = "; expires=" + date.toUTCString();
                        }
                        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
                    }, getCookie(name) {
                        if(typeof document !== 'undefined') {
                            var nameEQ = name + "=";
                            var ca = document.cookie.split(';');
                            for (var i = 0; i < ca.length; i++) {
                                var c = ca[i];
                                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                            }
                            return null;
                        }
                        return null;
                    },
                    addFavourite (variant_id){

                        let cookie = this.getCookie('favourite');
                        //  console.log(cookie, variant.id);
                        axios.post(route('favourite.store'), {
                            cookie : cookie,
                            variant_id: variant_id,
                        }).then((response) => {
                            this.is_favourite = response.data.is_favourite;//
                            this.website.favorite_variants = response.data.favourites;
                            this.website.favorite_url =  response.data.favourite_url;
                            this.setCookie('favourite',response.data.cookie,1000);
                        })
                    },
                    isFavourite(variant_id){
                        if(this.website.favorite_variants!==undefined){
                            let found = this.website.favorite_variants.find(element => element.id == variant_id);
                            if(found!==undefined){
                                return true;
                            }
                            return false;
                        }
                    },
                }
            })
            .use(ZiggyVue, Ziggy)
            .use(plugin)
            .mount(el);
    },
});

